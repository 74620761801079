import axiosInstance from '../../../axiosInstance.js';

const fetchRuleSchedules = async (ruleId) => {
  try {
    const response = await axiosInstance.get('/api/get-rule-schedules', {
      params: { ruleId },
    });

    const schedules = response.data;

    // Return schedules directly since no mapping/transformation is applied
    return schedules;
  } catch (error) {
    console.error('Error fetching rule schedules:', error);
    throw error;
  }
};

export default fetchRuleSchedules;

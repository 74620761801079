import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Transition,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import {
  XMarkIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../slices/notificationSlice.js';
import CreateRulePreview from '../../rules/CreateRulePreview.jsx';
import CreateFixPreview from '../../fixes/CreateFixPreview.jsx';
import ScheduledRun from './ScheduledRun.jsx';
import ScheduleForm from './ScheduleForm.jsx';
import fetchRuleSchedules from './fetchRuleSchedules.js';
import { fetchRuleById } from '../../../slices/rulesSlice.js';

const CreateSchedule = ({ isOpen, setIsOpen, selectedRule }) => {
  const dispatch = useDispatch();
  const [scheduledRuns, setScheduledRuns] = useState([]);
  const [isAddingRun, setIsAddingRun] = useState(false);
  const [frequency, setFrequency] = useState('hourly');
  const [startDate, setStartDate] = useState('');
  const [isRuleExpanded, setIsRuleExpanded] = useState(false);
  const [isFixExpanded, setIsFixExpanded] = useState(false);

  const previewFix = selectedRule?.fix;

  // Fetch schedules for the selected rule
  const fetchSchedules = async () => {
    try {
      const schedules = await fetchRuleSchedules(selectedRule._id);
      console.log('Fetched and normalized schedules:', schedules);
      setScheduledRuns(schedules);
    } catch (error) {
      console.error('Error fetching schedules:', error);
      dispatch(
        addNotification({
          type: 'error',
          message: 'Error fetching schedules',
          description: 'There was an error fetching the existing schedules.',
        })
      );
    }
  };

  const handleDialogClose = () => {
    if (selectedRule?._id) {
      // Dispatch fetchRuleById in the background without awaiting it
      dispatch(fetchRuleById(selectedRule._id))
        .then(() => {
          console.log('Rule data updated successfully.');
        })
        .catch((error) => {
          console.error('Error updating rule data:', error);
          dispatch(
            addNotification({
              type: 'error',
              message: 'Error updating rule',
              description: 'Failed to update the rule data. Please try again later.',
            })
          );
        });
    }

    // Close the dialog immediately
    setIsOpen(false);
  };

  // Handle opening/closing logic
  useEffect(() => {
    if (isOpen) {
      fetchSchedules(); // Fetch existing schedules when the modal is opened
    } else {
      // Reset state when the dialog is closed
      setScheduledRuns([]);
      setIsRuleExpanded(false);
      setIsFixExpanded(false);
      setIsAddingRun(false);
      setFrequency('hourly');
      setStartDate('');
    }
  }, [isOpen, selectedRule, dispatch]);

  const toggleRuleExpansion = () => {
    setIsRuleExpanded((prev) => !prev);
    setIsFixExpanded(false);
  };

  const toggleFixExpansion = () => {
    setIsFixExpanded((prev) => !prev);
    setIsRuleExpanded(false);
  };

  const renderScheduledRuns = () => {
    return scheduledRuns.map((run) => (
      <ScheduledRun
        key={run._id || run.tempId}
        scheduleId={run._id}
        frequency={run.frequency}
        nextRun={run.nextRun}
        status={run.status}
        onActionComplete={fetchSchedules} // Refresh schedules after an action
      />
    ));
  };

  const ExpansionIcon =
    isRuleExpanded || isFixExpanded ? ChevronDownIcon : ChevronRightIcon;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose} // Updated to handle fetching the rule
        className="relative z-10"
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 max-h-[calc(100vh-50px)] overflow-y-auto">
                {/* Close button */}
                <div className="absolute top-0 right-0 pt-4 pr-7">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={handleDialogClose} // Close and fetch rule
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Modal header */}
                <div>
                  <DialogTitle
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 flex items-center"
                  >
                    <CalendarDaysIcon className="h-10 w-10 mr-2" aria-hidden="true" />
                  </DialogTitle>
                </div>

                {/* Full Sentence with Independent Dropdowns */}
                <div className="mt-5 flex items-center space-x-2">
                  <p className="text-gray-700">
                    Scheduled runs of{' '}
                    <span
                      className="font-semibold text-indigo-500 hover:text-indigo-700 transition-colors duration-200 cursor-pointer underline"
                      onClick={toggleRuleExpansion}
                    >
                      {selectedRule?.ruleName}
                    </span>{' '}
                    with{' '}
                    <span
                      className="font-semibold text-indigo-500 hover:text-indigo-700 transition-colors duration-200 cursor-pointer underline"
                      onClick={toggleFixExpansion}
                    >
                      this fix:
                    </span>
                  </p>
                </div>

                {/* Rule Name Expansion Section */}
                {isRuleExpanded && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                    <CreateRulePreview rule={selectedRule} />
                  </div>
                )}

                {/* Fix Expansion Section */}
                {isFixExpanded && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                    <CreateFixPreview
                      previewFix={previewFix}
                      isDraggable={false}
                    />
                  </div>
                )}

                {/* Render scheduled runs */}
                {scheduledRuns.length > 0 && (
                  <div className="mt-4 py-8 bg-gray-50 p-4 px-12 mx-4 rounded-lg space-y-4">
                    {renderScheduledRuns()}
                  </div>
                )}

                {/* Add a Scheduled Run Button */}
                {!isAddingRun && (
                  <div className="ml-4 flex justify-left">
                    <button
                      onClick={() => setIsAddingRun(true)}
                      className="inline-flex text-sm items-center mt-10 mb-4 px-2 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-700"
                    >
                      <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                      Add a Scheduled Run
                    </button>
                  </div>
                )}

                {/* Scheduled Run Form */}
                {isAddingRun && (
                  <ScheduleForm
                    ruleId={selectedRule._id} // Pass the ruleId to ScheduleForm
                    frequency={frequency}
                    setFrequency={setFrequency}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    onCancel={() => setIsAddingRun(false)}
                    onScheduleAdded={() => {
                      fetchSchedules(); // Fetch schedules after adding a new one
                      setIsAddingRun(false); // Hide the form
                    }}
                  />
                )}
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateSchedule;
const commonTimeZones = [
    { zone: 'Pacific/Honolulu', offset: -10, shortName: 'HST' }, // Hawaii Standard Time
    { zone: 'America/Anchorage', offset: -9, shortName: 'AKST' }, // Alaska Standard Time
    { zone: 'America/Los_Angeles', offset: -8, shortName: 'PST' }, // Pacific Standard Time
    { zone: 'America/Denver', offset: -7, shortName: 'MST' }, // Mountain Standard Time
    { zone: 'America/Chicago', offset: -6, shortName: 'CST' }, // Central Standard Time
    { zone: 'America/New_York', offset: -5, shortName: 'EST' }, // Eastern Standard Time
    { zone: 'America/Halifax', offset: -4, shortName: 'AST' }, // Atlantic Standard Time
    { zone: 'UTC', offset: 0, shortName: 'UTC' }, // Coordinated Universal Time
    { zone: 'Europe/London', offset: 0, shortName: 'GMT' }, // Greenwich Mean Time
    { zone: 'Europe/Berlin', offset: 1, shortName: 'CET' }, // Central European Time
    { zone: 'Europe/Helsinki', offset: 2, shortName: 'EET' }, // Eastern European Time
    { zone: 'Africa/Johannesburg', offset: 2, shortName: 'SAST' }, // South Africa Standard Time
    { zone: 'Asia/Kolkata', offset: 5.5, shortName: 'IST' }, // India Standard Time
    { zone: 'Asia/Shanghai', offset: 8, shortName: 'CST' }, // China Standard Time
    { zone: 'Asia/Tokyo', offset: 9, shortName: 'JST' }, // Japan Standard Time
    { zone: 'Australia/Sydney', offset: 10, shortName: 'AEST' }, // Australian Eastern Standard Time
    { zone: 'Pacific/Auckland', offset: 12, shortName: 'NZST' }, // New Zealand Standard Time
  ];
  
  export default commonTimeZones;
  
  
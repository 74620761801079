import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrganization } from '../../slices/organizationSlice.js';
import { fetchTeam } from '../../slices/teamSlice.js';

export default function OrganizationSettings() {
  const dispatch = useDispatch();
  
  // Retrieve organization and team data from Redux state
  const organization = useSelector((state) => state.organization.data);
  const teamMembers = useSelector((state) => state.team.data);

  // Fetch data only if it's missing in the Redux state
  useEffect(() => {
    if (!organization) {
      dispatch(fetchOrganization());
    }
    if (!teamMembers || teamMembers.length === 0) {
      dispatch(fetchTeam());
    }
  }, [dispatch, organization, teamMembers]);

  console.log(organization, teamMembers);

  return (
    <div className="space-y-10">
      {/* Organization Information Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-10">
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Organization Information</h2>
          <p className="mt-1 text-sm text-gray-500">View and manage your organization details.</p>
        </div>
        <form className="md:col-span-2 space-y-6">
          <div>
            <label htmlFor="organization-name" className="block text-sm font-medium text-gray-700">
              Organization Name
            </label>
            <input
              type="text"
              id="organization-name"
              value={organization?.name || ''}
              readOnly
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="salesforce-instance" className="block text-sm font-medium text-gray-700">
              Salesforce Instance
            </label>
            <input
              type="text"
              id="salesforce-instance"
              value={organization?.salesforceInstance || ''}
              readOnly
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </form>
      </div>

      {/* Team Section */}
      <div className="space-y-6">
        <h2 className="text-lg font-semibold text-gray-900">Team Members</h2>
        <p className="text-sm text-gray-500">Manage your team members, roles <em>(coming soon)</em>, and permissions <em>(coming soon)</em> here.</p>
        
        {/* List of Team Members */}
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {teamMembers?.map((member) => (
            <div key={member.salesforceId} className="p-4 rounded-lg border border-gray-300 shadow-sm">
              <h3 className="text-sm font-medium text-gray-900">{member.name}</h3>
              <p className="mt-1 text-sm text-gray-500">Contact: {member.contactEmail}</p>
              <p className="mt-1 text-sm text-gray-500">Role: {member.role}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Additional Support Message */}
      <div className="mt-6">
        <p className="text-sm text-gray-600">
          If you have any questions or concerns, please reach out to{' '}
          <a
            href="mailto:support@tarn.co"
            className="text-indigo-600 hover:underline"
          >
            support@tarn.co
          </a>.
        </p>
      </div>
    </div>
  );
}
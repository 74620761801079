import React, { useState } from 'react';
import axiosInstance from '../../../axiosInstance';
import {
  CalendarDaysIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  MinusCircleIcon,
} from '@heroicons/react/24/outline';
import commonTimeZones from './commonTimeZones.js';

const ScheduledRun = ({ scheduleId, frequency, nextRun, status, onActionComplete }) => {
  const [isLoading, setIsLoading] = useState(false);

  // Format time and date
  const formattedTime = new Date(nextRun).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  });
  const formattedDate = new Date(nextRun).toLocaleDateString();

  // Determine the shortName for the time zone
  const getTimeZoneShortName = (date) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's local time zone
    const matchedTimeZone = commonTimeZones.find((tz) => tz.zone === timeZone);
    return matchedTimeZone ? matchedTimeZone.shortName : 'UTC'; // Default to UTC if not found
  };

  const timeZoneShortName = getTimeZoneShortName(new Date(nextRun));

  // API Call Handlers
  const handleResume = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.post('/api/resume-schedule', { scheduleId });
      onActionComplete();
    } catch (error) {
      console.error('Error resuming schedule:', error);
      alert('Failed to resume the schedule. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePause = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.post('/api/pause-schedule', { scheduleId });
      onActionComplete();
    } catch (error) {
      console.error('Error pausing schedule:', error);
      alert('Failed to pause the schedule. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.post('/api/delete-schedule', { scheduleId });
      onActionComplete();
    } catch (error) {
      console.error('Error deleting schedule:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Compute class names based on status
  const borderColorClass = status === 'paused' ? 'border-gray-400' : 'border-indigo-400';
  const calendarIconColorClass = status === 'paused' ? 'text-gray-500' : 'text-indigo-500';
  const backgroundColorClass = status === 'paused' ? 'bg-gray-100' : 'bg-white';
  const textStyleClass = status === 'paused' ? 'italic' : '';

  return (
    <div
      className={`p-4 border ${borderColorClass} rounded-md ${backgroundColorClass} flex justify-between items-center`}
    >
      {/* Calendar Icon */}
      <div className="w-1/8">
        <CalendarDaysIcon
          className={`h-8 w-8 mr-2 ${calendarIconColorClass}`}
          aria-hidden="true"
        />
      </div>

      {/* Schedule Details */}
      <div className="w-3/4 pl-2">
        <p className="text-sm text-gray-700">
          Status: <span className="font-bold">{status}</span>
        </p>
        <p className={`text-sm pt-1 text-gray-700 ${textStyleClass}`}>
          Frequency: <span className="font-bold">{frequency}</span>
        </p>
        <p className={`text-sm pt-1 text-gray-700 ${textStyleClass}`}>
          Next Run: <strong>{formattedTime}</strong> {timeZoneShortName} on {formattedDate}
        </p>
      </div>

      {/* Action Icons or Loading Indicator */}
      <div className="flex space-x-2">
        {isLoading ? (
          // Display loading indicator when waiting for server response
          <div className="flex items-center justify-center">
            <l-ring
              size="20"
              stroke="5"
              bg-opacity="0"
              speed="2"
              color="#8A79EC"
            ></l-ring>
          </div>
        ) : (
          // Display action buttons when not loading
          <>
            {status === 'paused' && (
              <PlayCircleIcon
                className="h-8 w-8 text-blue-500 hover:text-blue-700 cursor-pointer"
                aria-label="Resume"
                onClick={handleResume}
              />
            )}
            {status === 'active' && (
              <PauseCircleIcon
                className="h-8 w-8 text-yellow-500 hover:text-yellow-700 cursor-pointer"
                aria-label="Pause"
                onClick={handlePause}
              />
            )}
            <MinusCircleIcon
              className="h-8 w-8 text-red-500 hover:text-red-700 cursor-pointer"
              aria-label="Delete"
              onClick={handleDelete}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ScheduledRun;

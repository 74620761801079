import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import UserMenu from './UserMenu.jsx';
import { fetchUser } from '../../slices/userSlice';
import { fetchOrganization } from '../../slices/organizationSlice';
import { setIsBuyCreditsOpen } from '../../slices/uiSlice';

const Footer = () => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const user = useSelector((state) => state.user.data);
  const organization = useSelector((state) => state.organization.data);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchOrganization());
  }, [dispatch]);

  const openBuyCredits = () => {
    dispatch(setIsBuyCreditsOpen(true));
  };

  return (
    <div className="fixed bottom-0 left-0 w-full flex items-center justify-between p-2 border-t border-gray-300 bg-white">
      <div className="flex items-center pl-4">
        <UserMenu />
        <div className="ml-4">
          <div className="text-xs font-semibold text-gray-900">{user ? user.email : 'User Name'}</div>
          <div className="text-xxs text-gray-600">
            {organization ? (
              <a
                href={organization.salesforceInstance}
                className="text-gray-600 hover:text-indigo-600"
                target="_blank"
                rel="noopener noreferrer"
              >
                {organization.salesforceInstance}
              </a>
            ) : 'Salesforce Instance'}
          </div>
        </div>
      </div>
      <div className="absolute left-1/2 transform -translate-x-1/2 flex justify-center items-center pr-12">
        <img
          src="/assets/tarn_Icon_Purple.png"
          alt="Logo"
          className="h-7 w-7 cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
        {isHovered && (
          <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2 p-2 w-52 text-center text-xs bg-white border border-gray-300 rounded-lg shadow-lg">
            Made with ❤️ in Somerville, MA
          </div>
        )}
      </div>
      <div className="flex space-x-6 pr-4">
        <a
          href="https://tarn.co/terms-and-conditions"
          className="text-gray-600 hover:text-indigo-600 text-xs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
        <a
          href="https://tarn.co/data-policy"
          className="text-gray-600 hover:text-indigo-600 text-xs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Data Privacy Policy
        </a>
        <button onClick={openBuyCredits} className="text-gray-600 hover:text-indigo-600 text-xs">Buy Credits</button>
      </div>
    </div>
  );
};

export default Footer;
// fetchSchedules.js
import axiosInstance from '../../../axiosInstance.js'; // Adjust the path as needed
import parser from 'cron-parser'; // Import cron-parser to handle cron expressions

// Helper function to determine if two dates are on the same day
const isSameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

// Helper function to determine the frequency based on the cron expression
const getFrequency = (cronExpression) => {
  const parts = cronExpression.trim().split(' ');
  if (parts.length !== 5) {
    return 'Unknown';
  }
  const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;
  if (hour === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    return 'Hourly';
  } else if (dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    return 'Daily';
  } else {
    return 'Unknown';
  }
};

export const fetchSchedules = async (rules) => {
  try {
    const response = await axiosInstance.get('/api/get-org-schedules');
    console.log('Fetched Schedules:', response.data);

    // Array to hold all projected runs
    const projectedRuns = [];

    // Iterate through the fetched schedules
    if (Array.isArray(response.data)) {
      const now = new Date();
      const fourteenDaysLater = new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000);

      response.data.forEach((schedule) => {
        if (schedule && schedule.nextRun) {
          // Find the corresponding rule using ruleId
          const rule = rules.find((r) => r._id === schedule.ruleId);

          if (!rule) {
            console.warn(
              `Rule with ID ${schedule.ruleId} not found for schedule ${schedule._id}`
            );
            return;
          }

          // Determine frequency
          const frequency = getFrequency(schedule.cronExpression);

          // Initialize date to today
          let date = new Date(now);
          date.setHours(0, 0, 0, 0);

          while (date <= fourteenDaysLater) {
            let runTime;
            const dayStart = new Date(date);

            if (frequency === 'Hourly') {
              if (isSameDay(date, now)) {
                // Today: Find the next run after now
                try {
                  const interval = parser.parseExpression(schedule.cronExpression, {
                    currentDate: now,
                  });
                  runTime = interval.next().toDate();
                } catch (err) {
                  console.error(
                    `Invalid cron expression for schedule ID ${schedule._id}: ${err.message}`
                  );
                  break; // Exit the loop if the cron expression is invalid
                }
              } else {
                // Future days: Find the first run on that day
                try {
                  const interval = parser.parseExpression(schedule.cronExpression, {
                    currentDate: dayStart,
                  });
                  runTime = interval.next().toDate();

                  // Ensure the runTime is on the same day
                  if (!isSameDay(runTime, date)) {
                    date.setDate(date.getDate() + 1);
                    continue;
                  }
                } catch (err) {
                  console.error(
                    `Invalid cron expression for schedule ID ${schedule._id}: ${err.message}`
                  );
                  break; // Exit the loop if the cron expression is invalid
                }
              }

              // Push the runTime into projectedRuns
              projectedRuns.push({
                ...schedule,
                projectedRun: runTime,
                ruleName: rule.ruleName,
                salesforceObject: rule.salesforceObject,
                isNextRun: isSameDay(date, now),
                frequency: 'Hourly', // Mark as hourly
              });
            } else if (frequency === 'Daily') {
              // Daily schedules
              try {
                const interval = parser.parseExpression(schedule.cronExpression, {
                  currentDate: dayStart,
                });
                runTime = interval.next().toDate();

                // Ensure the runTime is on the same day
                if (!isSameDay(runTime, date)) {
                  date.setDate(date.getDate() + 1);
                  continue;
                }
              } catch (err) {
                console.error(
                  `Invalid cron expression for schedule ID ${schedule._id}: ${err.message}`
                );
                break; // Exit the loop if the cron expression is invalid
              }

              // Push the runTime into projectedRuns
              projectedRuns.push({
                ...schedule,
                projectedRun: runTime,
                ruleName: rule.ruleName,
                salesforceObject: rule.salesforceObject,
                isNextRun: isSameDay(runTime, now),
                frequency: 'Daily', // Mark as daily
              });
            } else {
              // For other frequencies or unknown, you can handle accordingly or skip
            }

            // Move to the next day
            date.setDate(date.getDate() + 1);
          }
        }
      });

      // Sort projectedRuns by projectedRun time in ascending order
      projectedRuns.sort((a, b) => new Date(a.projectedRun) - new Date(b.projectedRun));

      console.log('Sorted Projected Runs:', projectedRuns);
      return projectedRuns;
    } else {
      console.error('Unexpected data format:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching schedules:', error);
    return [];
  }
};
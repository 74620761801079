import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance.js'; // Import the axios instance
import moment from 'moment-timezone';
import commonTimeZones from './commonTimeZones.js';

const ScheduleForm = ({ ruleId, frequency, setFrequency, startDate, setStartDate, onCancel, onScheduleAdded }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeZone, setTimeZone] = useState('');

  useEffect(() => {
    // Set default time zone to the browser's time zone
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    setTimeZone(browserTimeZone);
  }, []);

  const handleAddRun = async () => {
    setIsSubmitting(true);
    try {
      // Parse 'startDate' in the selected time zone
      const dateInLocal = moment.tz(startDate, 'YYYY-MM-DDTHH:mm', timeZone);
  
      // Convert to UTC
      const dateInUTC = dateInLocal.utc().format();
  
      const response = await axiosInstance.post('/api/new-scheduled-run', {
        ruleId,
        frequency,
        startDate: dateInUTC,
      });
      console.log('Scheduled run added successfully:', response.data);
      onScheduleAdded();
    } catch (error) {
      console.error('Error adding scheduled run:', error);
      alert('Failed to add scheduled run. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return isSubmitting ? (
    <div className="flex justify-center items-center mt-6">
      <l-ring
        size="20"
        stroke="5"
        bg-opacity="0"
        speed="2"
        color="#8A79EC"
      ></l-ring>
    </div>
  ) : (
    <div className="mt-6 pt-6 p-4 mx-12 px-12 border rounded-md space-y-4 bg-gray-50">
      <div>
        <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">
          Run this:
        </label>
        <select
          id="frequency"
          name="frequency"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
        >
          <option value="hourly">Hourly</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
        </select>
      </div>

      <div>
        <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
          Starting on:
        </label>
        <input
          type="datetime-local"
          id="startDate"
          name="startDate"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>

      <div>
        <label htmlFor="timeZone" className="block text-sm font-medium text-gray-700">
          Time zone:
        </label>
        <select
          id="timeZone"
          name="timeZone"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={timeZone}
          onChange={(e) => setTimeZone(e.target.value)}
        >
          {commonTimeZones.map(({ zone, offset }) => (
            <option key={zone} value={zone}>
              {zone} (UTC {offset >= 0 ? '+' : ''}{offset})
            </option>
          ))}
        </select>
      </div>

      <div className="flex justify-end space-x-2 pt-6">
        <button
          type="button"
          className="inline-flex items-center px-4 py-1 bg-indigo-500 text-sm text-white rounded-md hover:bg-indigo-700"
          onClick={handleAddRun}
        >
          Add
        </button>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 bg-gray-300 text-sm text-gray-800 rounded-md hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ScheduleForm;

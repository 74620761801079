import React, { useState, useEffect, useRef } from 'react';
import { fetchSchedules } from './fetchSchedules.js';
import ScheduledRule from './ScheduledRule.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRules } from '../../../slices/rulesSlice.js';

const Schedule = () => {
  const [schedulesByDay, setSchedulesByDay] = useState({});
  const [currentWeekStart, setCurrentWeekStart] = useState(new Date());
  const [initialLoad, setInitialLoad] = useState(true);
  const [projectedRuns, setProjectedRuns] = useState([]);
  const [numVisibleDays, setNumVisibleDays] = useState(4);

  const containerRef = useRef(null); // Reference to the container element

  // Calculate today's date and the maximum allowed date (14 days from today)
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of today
  const maxDate = new Date(today);
  maxDate.setDate(maxDate.getDate() + 14); // Maximum allowed date is 14 days from today

  // Get rules from Redux store
  const rules = useSelector((state) => state.rules.data);
  const rulesStatus = useSelector((state) => state.rules.status);
  const dispatch = useDispatch();

  // Ensure rules are loaded
  useEffect(() => {
    if (rulesStatus === 'idle') {
      dispatch(fetchRules());
    }
  }, [dispatch, rulesStatus]);

  // Initial load to fetch schedules once
  useEffect(() => {
    if (rulesStatus === 'succeeded' && initialLoad) {
      loadSchedules();
      setInitialLoad(false); // Set to false after initial load
    }
  }, [rulesStatus, rules, initialLoad]);

  const loadSchedules = async () => {
    const fetchedProjectedRuns = await fetchSchedules(rules); // Pass rules as argument
    setProjectedRuns(fetchedProjectedRuns); // Store fetched runs
    updateSchedulesByDay(currentWeekStart, fetchedProjectedRuns); // Initialize schedule by day
  };

  // Update the `schedulesByDay` state based on the visible dates
  const updateSchedulesByDay = (startDate, runs) => {
    const schedules = {};
    const visibleDates = getVisibleDates(startDate);

    visibleDates.forEach((date) => {
      const dayKey = date.toDateString();
      schedules[dayKey] = [];
    });

    runs.forEach((run) => {
      const runDate = new Date(run.projectedRun);
      const dayKey = runDate.toDateString();

      // Only include runs within the visible dates
      if (schedules[dayKey]) {
        schedules[dayKey].push(run);
      }
    });

    setSchedulesByDay(schedules);
  };

  // Calculate the dates for the visible days based on a start date
  const getVisibleDates = (startDate) => {
    const dates = [];
    const date = new Date(startDate); // Use a copy of the start date
    for (let i = 0; i < numVisibleDays; i++) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  // Calculate the number of visible days based on container width
  useEffect(() => {
    const calculateNumVisibleDays = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const dayWidth = 260; // Adjust this width to match the actual width of your day columns
        const daysThatCanFit = Math.floor(containerWidth / dayWidth);
        setNumVisibleDays(daysThatCanFit > 0 ? daysThatCanFit : 1);
      }
    };

    calculateNumVisibleDays(); // Calculate on mount

    window.addEventListener('resize', calculateNumVisibleDays);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', calculateNumVisibleDays);
    };
  }, [containerRef]);

  // Recalculate visible dates when numVisibleDays changes
  useEffect(() => {
    updateSchedulesByDay(currentWeekStart, projectedRuns);
  }, [numVisibleDays]);

  // Shift the current view forward by numVisibleDays without re-fetching data, if within the 14-day limit
  const handleNextDays = () => {
    const newStart = new Date(currentWeekStart);
    newStart.setDate(newStart.getDate() + numVisibleDays); // Shift forward by numVisibleDays

    // Check if newStart exceeds the maxDate
    if (newStart <= maxDate) {
      setCurrentWeekStart(newStart);
      updateSchedulesByDay(newStart, projectedRuns); // Update visible days
    }
  };

  // Shift the current view backward by numVisibleDays without re-fetching data
  const handlePreviousDays = () => {
    const newStart = new Date(currentWeekStart);
    newStart.setDate(newStart.getDate() - numVisibleDays); // Shift backward by numVisibleDays

    // Prevent shifting to dates before today
    if (newStart >= today) {
      setCurrentWeekStart(newStart);
      updateSchedulesByDay(newStart, projectedRuns); // Update visible days
    }
  };

  // Set the view back to today as the first column
  const handleToday = () => {
    setCurrentWeekStart(today);
    updateSchedulesByDay(today, projectedRuns); // Update visible days
  };

  // Calculate visible dates based on the current start date
  const visibleDates = getVisibleDates(currentWeekStart);

  return (
    <div className="mt-5 w-full px-4 h-[calc(100vh-100px)] overflow-x-hidden">
      {/* Title and Buttons */}
      <div className="flex justify-between items-center mr-10">
        <h2 className="text-2xl ml-6 font-bold">Schedule</h2>
        <div className="flex space-x-2">
          <button
            onClick={handleToday}
            className="px-3 py-1 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-500"
          >
            Today
          </button>
          <button
            onClick={handlePreviousDays}
            className={`px-3 py-1 text-sm rounded ${
              new Date(currentWeekStart).toDateString() === new Date().toDateString()
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                : 'bg-gray-300 text-gray-700 hover:bg-gray-400'
            }`}
            disabled={new Date(currentWeekStart).toDateString() === new Date().toDateString()}
          >
            Back
          </button>
          <button
            onClick={handleNextDays}
            className={`px-3 py-1 text-sm rounded ${
              new Date(currentWeekStart.getTime() + numVisibleDays * 24 * 60 * 60 * 1000) >= maxDate
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                : 'bg-indigo-600 text-white hover:bg-indigo-500'
            }`}
            disabled={new Date(currentWeekStart.getTime() + numVisibleDays * 24 * 60 * 60 * 1000) >= maxDate}
          >
            Next
          </button>
        </div>
      </div>

      {/* Schedule Container */}
      <div ref={containerRef} className="mt-5 p-3 overflow-x-auto">
        <div className="flex">
          {/* Map over visible days and render each day */}
          {visibleDates.map((date) => {
            const dayKey = date.toDateString();
            const dayName = date.toLocaleDateString(undefined, { weekday: 'long' });
            const dayNumber = date.toLocaleDateString(undefined, {
              day: 'numeric',
              month: 'short',
            });

            // Determine if this day is today to apply a different background color
            const isToday = dayKey === today.toDateString();

            return (
              <div
                key={dayKey}
                className="w-[250px] mx-1 bg-gray-200 rounded-lg shadow overflow-y-auto"
              >
                {/* Sticky Header for Day and Date */}
                <div
                  className={`sticky top-0 z-10 p-2 ${
                    isToday ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  } rounded-t-lg`}
                >
                  <h3 className="text-lg font-semibold">{dayName}</h3>
                  <p className="text-sm">{dayNumber}</p>
                </div>
                {/* Rules Container */}
                <div className="p-2 max-h-[61vh] overflow-y-auto">
                  {/* Render schedules for this day */}
                  {schedulesByDay[dayKey] && schedulesByDay[dayKey].length > 0 ? (
                    schedulesByDay[dayKey].map((schedule, index) => (
                      <ScheduledRule key={`${dayKey}-${index}`} schedule={schedule} />
                    ))
                  ) : (
                    <p className="text-sm text-gray-500">No schedules</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
import React from 'react';
import { useSelector } from 'react-redux';
import HeroIcon from '../../HeroIcon.jsx';

const ScheduledRule = ({ schedule }) => {
  const { ruleName, salesforceObject, projectedRun, isNextRun, frequency } = schedule;
  const salesforceObjects = useSelector((state) => state.salesforceObjects.data);
  const matchingObject = salesforceObjects.find((obj) => obj.name === salesforceObject);
  const heroIcon = matchingObject?.heroIcon;
  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  // Format the time without a leading zero for hours
  const formattedTime = new Date(projectedRun).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  });

  return (
    <div
      className={`p-3 mb-3 rounded-lg shadow ${isNextRun ? 'bg-white border-l-4 border-indigo-500' : 'bg-white border border-gray-300'
        }`}
    >
      {/* Top Section */}
      <div className="flex items-center mb-2">
        {/* Icon - 1/4th */}
        <div className="w-1/4 flex justify-center">
          {heroIcon && (
            <HeroIcon iconName={heroIcon} type="outline" className="h-6 w-6 text-gray-700" />
          )}
        </div>
        {/* Rule Name - 3/4th */}
        <div className="w-3/4 pl-2">
          <p className="text-xs font-medium text-gray-900 break-words">{ruleName}</p>
        </div>
      </div>
      {/* Bottom Section */}
      <div className="flex items-center pt-3 border-t border-gray-200">
        {/* Time - Left Half */}
        <div className="w-1/2">
          <p
            className={classNames(
              'text-sm font-semibold ml-3',
              isNextRun ? 'text-indigo-600' : 'text-gray-800'
            )}
          >
            {formattedTime}
          </p>
        </div>
        {/* Frequency - Right Half */}
        <div className="w-1/2 text-right pr-3">
          <p
            className={classNames(
              'inline-block bg-indigo-50 text-indigo-700 ring-indigo-600/20',
              'whitespace-nowrap rounded-md px-2 py-0.5 text-xs font-medium ring-1 ring-inset'
            )}
          >
            {frequency}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScheduledRule;
import React, { useState, useEffect, useRef } from 'react';
import { Dialog, Transition, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCreateRuleOpen } from '../../slices/uiSlice.js';
import { fetchRules } from '../../slices/rulesSlice.js';
import CreateRulePreview from './CreateRulePreview.jsx';
import CreateRuleFeedback from './CreateRuleFeedback.jsx';
import ValidateRule from './ValidateRule.jsx';
import { lineWobble, ring } from 'ldrs';
import HeroIcon from '../HeroIcon.jsx';
import axiosInstance from '../../axiosInstance.js';
import { fetchOrgCredits } from '../../slices/orgCreditsSlice.js';
import AvailableFields from './AvailableFields.jsx';
import CreateIssuePreview from './CreateIssuePreview.jsx';
import { fetchSalesforceObjectByName } from '../../slices/salesforceObjectsSlice.js';
import { setSelectedObject } from '../../slices/selectedObjectSlice.js';

const CreateRule = ({ selectedSalesforceObject }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.isCreateRuleOpen);

  const [rulePrompts, setRulePrompts] = useState([]);
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(false);

  // Cycle through prompts every 2 seconds
  useEffect(() => {
    if (rulePrompts.length > 0) {
      const interval = setInterval(() => {
        setCurrentPromptIndex((prevIndex) => (prevIndex + 1) % rulePrompts.length);
      }, 4000); // Change prompt every 2 seconds
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [rulePrompts]);

  const selectedRuleId = useSelector((state) => state.selectedRuleId);
  const currentRules = useSelector((state) => state.rules.data);
  const selectedRule = currentRules.find((rule) => rule._id === selectedRuleId);

  const selectedObject = useSelector((state) => state.selectedObject);


  const [description, setDescription] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [previewRule, setPreviewRule] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [validationStatus, setValidationStatus] = useState(null);
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [selectedView, setSelectedView] = useState('rule');

  const [validations, setValidations] = useState(null);

  const feedbackRef = useRef(null);
  const textareaRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);



  const insertFieldAtCursor = (fieldName) => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = textarea.value;

    const updatedText = `${text.substring(0, start)}${fieldName}${text.substring(end)}`;
    setDescription(updatedText);

    // Move the cursor to the end of the inserted text
    setTimeout(() => {
      textarea.setSelectionRange(start + fieldName.length, start + fieldName.length);
      textarea.focus();
    }, 0);
  };

  lineWobble.register();
  ring.register();

  useEffect(() => {
    if (!isOpen) {
      // Reset state when modal is closed
      setDescription('');
      setIsGenerating(false);
      setIsGenerated(false);
      setPreviewRule(null);
      setIsEditable(true);
      setIsFeedbackVisible(false);
      setValidationStatus(null);
      setShouldValidate(false);
      setIsExpanded(false);
      setRulePrompts([]); // Reset rulePrompts
      setCurrentPromptIndex(0);
    } else {
      // Set loading state to true while fetching prompts
      setIsLoadingPrompts(true);

      console.log(selectedSalesforceObject);

      // Check if `rulePromptRecs` are already present in `selectedSalesforceObject`
      if (selectedSalesforceObject?.rulePromptRecs?.length > 0) {
        setRulePrompts(selectedSalesforceObject.rulePromptRecs);
        setIsLoadingPrompts(false);
      } else {
        // Fetch rule prompts if not available in `selectedSalesforceObject`
        axiosInstance.get(`/api/get-rule-prompt-recs/${selectedSalesforceObject.name}`)
          .then(response => {
            setRulePrompts(response.data);
            setIsLoadingPrompts(false);

            // Fetch the updated Salesforce object by name and set it as selected once it returns
            dispatch(fetchSalesforceObjectByName(selectedSalesforceObject.name))
              .unwrap()
              .then((updatedObject) => {
                dispatch(setSelectedObject(updatedObject)); // Set selectedObject with the updated data
              })
              .catch(error => {
                console.error('Error fetching updated Salesforce object:', error);
              });
          })
          .catch(error => {
            console.error('Error fetching rule prompt recommendations:', error);
            setIsLoadingPrompts(false);
          });
      }

      if (selectedRule) {
        // Pre-populate fields when editing
        setDescription(selectedRule.rulePrompt || '');
        setPreviewRule(selectedRule || null);
        setIsGenerated(true);
        setIsEditable(false);
        setValidationStatus('valid');
        setShouldValidate(false); // Do not validate when editing
      } else {
        // New rule creation
        setDescription('');
        setPreviewRule(null);
        setIsGenerated(false);
        setIsEditable(true);
        setShouldValidate(false);
        setValidationStatus(null);
      }
    }
  }, [isOpen, selectedRule, selectedSalesforceObject]);


  const onSave = async (e) => {
    e.preventDefault();
    try {
      const saveResponse = await axiosInstance.post('/api/save-rule', {
        ruleId: selectedRule?._id,
        rule: previewRule, // This now includes the updated issueLayout
        prompt: description,
      });

      dispatch(fetchRules());
      dispatch(setIsCreateRuleOpen(false));
    } catch (error) {
      console.error('Error saving rule:', error);
    }
  };

  const onGenerateRule = async () => {
    setIsGenerating(true);
    setIsEditable(false);
    collapseAdvanced();

    try {
      const response = await axiosInstance.post('/api/generate-rule', {
        salesforceObject: selectedSalesforceObject,
        description,
      });

      setPreviewRule(response.data.ruleData || null);
      setIsGenerated(true);
      setIsSaveEnabled(true);
      setShouldValidate(true); // Enable validation after generating a new rule
      setSelectedView('rule'); // Ensure the "Rule" view is selected after generation
      dispatch(fetchOrgCredits());
    } catch (error) {
      console.error('Error previewing rule:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const onEditPrompt = () => {
    setIsEditable(true);
    setIsSaveEnabled(false); // Disable the Save button when Edit Prompt is clicked
    setShouldValidate(false); // Disable validation until new generation
    setValidationStatus(null); // Reset validation status
  };

  const collapseAdvanced = () => {
    setIsExpanded(false);
  };

  const handleValidationStatusChange = (status, newValidations) => {
    setValidationStatus(status);
    setValidations(newValidations); // Use the newValidations parameter
  };

  // CreateRule.jsx
  const handleValidationComplete = () => {
    setShouldValidate(false);
  };

  // CreateRule.jsx
  const handleIssueLayoutChange = (updatedIssueLayout) => {
    setPreviewRule((prevRule) => {
      if (prevRule) {
        // Only update if issueLayout has actually changed
        if (prevRule.issueLayout !== updatedIssueLayout) {
          return { ...prevRule, issueLayout: updatedIssueLayout };
        }
        return prevRule;
      }
      return prevRule;
    });
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog open={isOpen} onClose={() => dispatch(setIsCreateRuleOpen(false))} className="relative z-10">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 max-h-[calc(100vh-50px)] overflow-y-auto">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => dispatch(setIsCreateRuleOpen(false))}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center">
                    {selectedSalesforceObject && selectedSalesforceObject.heroIcon ? (
                      <HeroIcon
                        iconName={selectedSalesforceObject.heroIcon}
                        type="solid"
                        className="h-8 w-8 text-[#8A79EC]"
                      />
                    ) : (
                      <p className="text-gray-500">No icon selected</p>
                    )}
                  </div>
                  <div className="mt-1 text-center">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      New{' '}
                      {selectedSalesforceObject && selectedSalesforceObject.label
                        ? selectedSalesforceObject.label
                        : 'Salesforce'}{' '}
                      Rule
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        This prompt will generate a Rule - which is basically a Salesforce report. Let's find the records you want to fix!
                      </p>
                    </div>
                  </div>
                </div>
                <form className="mt-6" onSubmit={onSave}>
                  <div className="">
                    {/* Generate Rule Button */}
                    <div>
                      <div className="mt-2 relative">
                        <textarea
                          id="description"
                          name="description"
                          rows={4}
                          ref={textareaRef}
                          className={`block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${!isEditable || isLoadingPrompts ? 'bg-gray-200 cursor-not-allowed' : ''
                            }`}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          disabled={!isEditable || isLoadingPrompts} // Disable when not editable or loading prompts
                        />

                        {isGenerated && (
                          <button
                            type="button"
                            className="absolute right-2 bottom-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={onEditPrompt}
                          >
                            Edit Prompt
                          </button>
                        )}
                      </div>
                    </div>

                    {/* Available Fields Component */}
                    <div className="mt-4">
                    </div>

                    {isGenerating && (
                      <div className="flex justify-center items-center mt-4">
                        <l-line-wobble
                          size="300"
                          stroke="5"
                          bg-opacity="0.1"
                          speed="1.75"
                          color="#8A79EC"
                        ></l-line-wobble>
                      </div>
                    )}
                    {/* Loading state for when prompts are being fetched */}
                    {isLoadingPrompts && (
                      <div className="flex justify-center mt-4">
                        <p className="text-sm text-gray-500 mr-2">Generating suggested prompts...</p>
                        <l-ring
                          size="20"
                          stroke="5"
                          bg-opacity="0"
                          speed="2"
                          color="#8A79EC"
                        ></l-ring>
                      </div>
                    )}
                    {/* Suggested prompts, only displayed when there are rulePrompts */}
                    {!isLoadingPrompts && rulePrompts.length > 0 && !isGenerated && !description && (
                      <div
                        className="mt-4 ml-2 text-sm font-semibold text-indigo-600 min-h-[3rem] max-h-[5rem] overflow-y-auto"
                        onClick={() => setDescription(rulePrompts[currentPromptIndex]?.prompt)}
                        onMouseEnter={(e) => (e.currentTarget.style.color = '#3730a3')}
                        onMouseLeave={(e) => (e.currentTarget.style.color = '#5a67d8')}
                        style={{ cursor: 'pointer' }}
                      >
                        <strong className="text-gray-600">Suggested Prompt:</strong> {rulePrompts[currentPromptIndex]?.prompt}
                      </div>
                    )}
                    <AvailableFields
                      onInsertField={insertFieldAtCursor}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      onCollapse={collapseAdvanced}
                      isEditable={isEditable}
                    />

                    {isGenerated && previewRule && (
                      <div className="flex space-x-4 mt-2">
                        <div className="w-2/3">
                          <div className="mt-4 p-4 bg-gray-100 rounded-md">
                            <div className="flex ml-2 space-x-3 mb-3">
                              <button
                                type="button"
                                className={`px-3 py-1 rounded-lg text-sm ${selectedView === 'rule' ? 'bg-indigo-600 text-white' : 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'}`}
                                onClick={() => setSelectedView('rule')}
                              >
                                Rule
                              </button>
                              <button
                                type="button"
                                className={`px-3 py-1 rounded-lg text-sm ${selectedView === 'issue' ? 'bg-indigo-600 text-white' : 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'}`}
                                onClick={() => setSelectedView('issue')}
                              >
                                Issue Preview
                              </button>
                            </div>
                            {selectedView === 'rule' ? (
                              <CreateRulePreview rule={previewRule} />
                            ) : (
                              <CreateIssuePreview
                                validations={validations}
                                rule={previewRule}
                                onIssueLayoutChange={handleIssueLayoutChange}
                              />
                            )}
                          </div>
                        </div>
                        <div className="w-1/3 mt-4 flex justify-center items-center">
                          <div className="flex h-full w-full p-4 bg-gray-100 rounded-md">
                            <ValidateRule
                              rule={previewRule}
                              onValidationStatusChange={(status, validations) => handleValidationStatusChange(status, validations)}
                              shouldValidate={shouldValidate}
                              onValidationComplete={handleValidationComplete}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {/* "Not what you expected?" Button */}
                    {isGenerated && previewRule && !isFeedbackVisible && (
                      <div className="flex justify-start mt-4 ml-4">
                        <button
                          type="button"
                          className="text-xsm text-indigo-600 underline hover:text-indigo-600 hover:underline focus:outline-none"
                          onClick={() => {
                            setIsFeedbackVisible(true);
                            setTimeout(() => feedbackRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100); // Scroll to feedback form
                          }}
                        >
                          Not what you expected?
                        </button>
                      </div>
                    )}
                    {isGenerated && previewRule && isFeedbackVisible && (
                      <div ref={feedbackRef}>
                        <CreateRuleFeedback
                          previewRule={previewRule}
                          prompt={description}
                          onClose={() => setIsFeedbackVisible(false)} // Hide feedback form on close
                        />
                      </div>
                    )}
                  </div>

                  {/* Buttons */}
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => dispatch(setIsCreateRuleOpen(false))}
                      disabled={isGenerating}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 ${isGenerating || !isEditable
                        ? 'cursor-not-allowed bg-gray-400'
                        : 'bg-indigo-600 text-white hover:bg-indigo-500'
                        } sm:col-start-2 sm:mt-0`}
                      onClick={onGenerateRule}
                      disabled={isGenerating || !isEditable}
                    >
                      Generate Rule
                    </button>
                    <button
                      type="submit"
                      className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:col-start-3 ${!isGenerated || isFeedbackVisible || validationStatus === 'invalid' || !isSaveEnabled
                        ? 'cursor-not-allowed bg-gray-400 text-gray-500'
                        : 'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        }`}
                      disabled={!isGenerated || isFeedbackVisible || validationStatus === 'invalid' || !isSaveEnabled} // Update disabled condition
                    >
                      Save
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateRule;
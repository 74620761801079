import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function AccountSettings() {
  // Access user data from Redux store
  const user = useSelector((state) => state.user.data);

  // Log user data whenever it changes
  useEffect(() => {
    if (user) {
      console.log('User data:', user);
    }
  }, [user]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-10">
      {/* Personal Information Section */}
      <div>
        <h2 className="text-lg font-semibold text-gray-900">Account Details</h2>
        <p className="mt-1 text-sm text-gray-500">
          These values are set permanently. Please reach out to support if something looks incorrect.
        </p>
      </div>
      <form className="md:col-span-2 space-y-6">
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 sm:text-sm"
              defaultValue={user?.name || ''} // Prefill with user's name
              readOnly // Make field non-editable
            />
          </div>

          <div>
            <label htmlFor="salesforce-email" className="block text-sm font-medium text-gray-700">
              Salesforce Login Email
            </label>
            <input
              type="email"
              id="salesforce-email"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 sm:text-sm"
              defaultValue={user?.email || ''} // Prefill with user's Salesforce email
              readOnly // Make field non-editable
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
          <div>
            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
              Contact Email
            </label>
            <input
              type="email"
              id="contact-email"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 sm:text-sm"
              defaultValue={user?.contactEmail || ''} // Prefill with user's contact email
              readOnly // Make field non-editable
            />
          </div>

          <div>
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <input
              type="text"
              id="role"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 sm:text-sm"
              defaultValue={user?.role || ''} // Prefill with user's role
              readOnly // Make field non-editable
            />
          </div>
        </div>
      </form>

      {/* Additional Support Message */}
      <div className="md:col-span-3 mt-6">
        <p className="text-sm text-gray-600">
          If you have any questions or concerns, please reach out to{' '}
          <a
            href="mailto:support@tarn.co"
            className="text-indigo-600 hover:underline"
          >
            support@tarn.co
          </a>.
        </p>
      </div>
    </div>
  );
}
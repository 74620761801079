'use client';

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch
import { Switch } from '@headlessui/react';
import axiosInstance from '../../axiosInstance.js'; // Import your axios instance
import { fetchUser } from '../../slices/userSlice';

export default function NotificationsSettings() {
  // Access user data directly from Redux state
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const [productEmails, setProductEmails] = useState(true);
  const [marketingEmails, setMarketingEmails] = useState(true);
  const [isUpdating, setIsUpdating] = useState({
    productEmails: false,
    marketingEmails: false,
  });

  // Update states based on user preferences
  useEffect(() => {
    if (user?.emailPreferences) {
      setProductEmails(user.emailPreferences.productEmails ?? true);
      setMarketingEmails(user.emailPreferences.marketingEmails ?? true); // Change this line
    }
  }, [user]);


  const updateEmailPreference = async (type, value) => {
    setIsUpdating((prev) => ({ ...prev, [type]: true })); // Show the animation
    try {
      const response = await axiosInstance.post('/api/change-email-preference', {
        preferenceType: type,
        preferenceValue: value,
      });
      console.log(`${type} updated successfully:`, response.data);

      // Dispatch fetchUser to refresh the user data
      await dispatch(fetchUser());
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
    } finally {
      setIsUpdating((prev) => ({ ...prev, [type]: false })); // Hide the animation
    }
  };

  // Handlers for toggling preferences
  const handleProductEmailsToggle = (value) => {
    setProductEmails(value);
    updateEmailPreference('productEmails', value);
  };

  const handleMarketingEmailsToggle = (value) => {
    setMarketingEmails(value);
    updateEmailPreference('marketingEmails', value);
  };

  return (
    <div className="w-3/4 lg:w-1/2 text-gray-700 space-y-6">
      <h2 className="text-lg font-semibold">Notifications Settings</h2>

      {/* Critical Notifications - Always On */}
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium mr-4">
          Critical product and account notifications
        </label>
        <Switch
          checked={true} // Always on
          onChange={() => { }} // Disabled
          className="relative inline-flex h-6 w-11 shrink-0 cursor-not-allowed rounded-full border-2 border-transparent bg-indigo-600"
        >
          <span className="sr-only">Critical product and account notifications</span>
          <span
            aria-hidden="true"
            className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow translate-x-5 transition duration-200"
          />
        </Switch>
      </div>

      {/* TarnData Product Emails */}
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium mr-4">
          <strong>tarnData </strong>product emails - weekly digests, summaries, and more
        </label>
        <div className="relative flex items-center">
          <Switch
            checked={productEmails}
            onChange={handleProductEmailsToggle}
            className={`relative inline-flex h-6 w-11 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out ${productEmails ? 'bg-indigo-600' : 'bg-gray-200'
              }`}
          >
            <span className="sr-only">TarnData product emails</span>
            <span
              aria-hidden="true"
              className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ${productEmails ? 'translate-x-5' : 'translate-x-0'
                }`}
            />
          </Switch>
          {isUpdating.productEmails && (
            <div className="absolute right-[-30px]">
              <l-ring
                size="20"
                stroke="5"
                bg-opacity="0"
                speed="2"
                color="#8A79EC"
              ></l-ring>
            </div>
          )}
        </div>
      </div>

      {/* Tarn Marketing Emails */}
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium mr-4">
          Tarn marketing and promotional emails
        </label>
        <div className="relative flex items-center">
          <Switch
            checked={marketingEmails}
            onChange={handleMarketingEmailsToggle}
            className={`relative inline-flex h-6 w-11 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out ${marketingEmails ? 'bg-indigo-600' : 'bg-gray-200'
              }`}
          >
            <span className="sr-only">Tarn marketing and promotional emails</span>
            <span
              aria-hidden="true"
              className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ${marketingEmails ? 'translate-x-5' : 'translate-x-0'
                }`}
            />
          </Switch>
          {isUpdating.marketingEmails && (
            <div className="absolute right-[-30px]">
              <l-ring
                size="20"
                stroke="5"
                bg-opacity="0"
                speed="2"
                color="#8A79EC"
              ></l-ring>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BellIcon, CodeBracketSquareIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import Version from './Version.jsx';
import SearchRules from './SearchRules.jsx';
import axiosInstance from '../../axiosInstance.js';
import { addNotification } from '../../slices/notificationSlice.js';
import { setIsGeneralFeedbackOpen } from '../../slices/uiSlice.js';

const Header = () => {
  const [loading, setLoading] = useState(false);
  const selectedObject = useSelector((state) => state.selectedObject);
  const dispatch = useDispatch();

  // Function to handle Generate Test Data button click
  const handleGenerateTestData = async () => {
    if (selectedObject) {
      setLoading(true);
      try {
        const response = await axiosInstance.post('/api/generate-test-data', { object: selectedObject });

        console.log(response.data);
        // Dispatch a notification for each created record
        response.data.forEach((record) => {
          const recordDetails = Object.entries(record)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');

          dispatch(addNotification({
            type: 'success',
            message: 'Record created successfully',
            description: recordDetails,
          }));
        });

        console.log('Test data generated successfully', response.data);
      } catch (error) {
        dispatch(addNotification({
          type: 'error',
          message: 'Error generating test data',
          description: error.response?.data?.error || 'An unexpected error occurred.',
        }));

        console.error('Error generating test data', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Function to handle Send Feedback button click
  const handleSendFeedback = () => {
    dispatch(setIsGeneralFeedbackOpen(true)); // Trigger the feedback modal
  };

  // Check if selectedObject is 'Account', 'Contact', or 'Opportunity'
  const isTestDataButtonVisible = selectedObject?.name === 'Account' || selectedObject?.name === 'Contact' || selectedObject?.name === 'Opportunity';

  return (
    <header className="shrink-0 py-1">
      <div className="mx-auto flex h-16 max-w-8xl items-center justify-between pl-3 pr-4 sm:pr-4 lg:pr-8">
        <div className="flex items-center w-full">
          <img
            className="h-8 mt-1 w-auto"
            src="/assets/Data_Logo_01.png"
            alt="Tarn Logo"
          />
          <div className="ml-12 flex-grow">
            <SearchRules />
          </div>
        </div>
        <div className="flex items-center gap-x-8 flex-none z-50">
          {loading && (
            <l-mirage size="80" speed="2.5" color="#6366f1"></l-mirage>
          )}

          <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-[#8A79EC]">
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <Version />
        </div>
      </div>
    </header>
  );
};

export default Header;